<template>
  <div>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-9 col-md-12">
          <div class="card mb-3" v-loading="loading">
            <div class="card-header">
              <h3 class="mb-0">Instant Bank Payment from list</h3>
            </div>

            <div class="card-body min-vh">
              <div class="row justify-content-md-center">
                <div class="col-xl-7 col-lg-8 col-md-12">
                  <el-card class="box-card">
                    <div slot="header" class="clearfix">
                      <span>Payee Detail</span>
                    </div>
                    <div class="text-sm">
                      <div>
                        Account name : <b>{{ payeeInfo.name }}</b>
                      </div>
                      <div>
                        Account currency : <b>{{ payeeInfo.currencyCode }}</b>
                      </div>
                    </div>
                  </el-card>
                  <el-card class="box-card mt-2 mb-2">
                    <div slot="header" class="clearfix">
                      <span>Detail of payment</span>
                    </div>
                    <div class="text-sm">
                      <div>
                        {{ paymentType[payeeInfo.payByType] }} :
                        <b>{{ payeeInfo.payeeInfo }}</b>
                      </div>
                    
                      <div>
                        Currency : <b>{{ payeeInfo.currencyCode }}</b>
                      </div>
                    </div>
                  </el-card>
                </div>
              </div>

              <div class="row justify-content-md-center">
                <div class="col-xl-7 col-lg-8 col-md-12">
                  <el-card class="box-card">
                    <div slot="header" class="clearfix">
                      <span>Payment form</span>
                    </div>
                    <el-form
                      ref="formInstant"
                      :model="form"
                      :rules="formRule"
                      :label-position="labelPosition"
                      label-width="120px"
                    >
                      <el-form-item label="BankholderID" prop="bankholderid">
                        <el-select
                          v-model="form.bankholderid"
                          @change="getBalance"
                          placeholder="BankholderID"
                          class="form-control-alternative"
                        >
                          <el-option
                            v-for="item of bankAccount"
                            :key="item.uid"
                            :label="`${item.currency} (${item.cardholderid})`"
                            :value="item.cardholderid"
                          ></el-option>
                        </el-select>
                        <div
                          class="text-xs pl-3 mt-1"
                          v-loading="loadingBalance"
                        >
                          {{ balance }}
                        </div>
                      </el-form-item>
                      <!-- 
                    <el-form-item label="To Account No./IBAN" prop="account">
                      <el-input v-model="form.account" placeholder="Account No./IBAN" v-input-filter class="form-control-alternative"></el-input>
                    </el-form-item> -->

                      <!-- <el-form-item label="Payment By" prop="payByType">
                      <el-select
                        v-model="form.payByType"
                        class="form-control-alternative"
                      >
                        <el-option
                          label="Mobile phone number"
                          value="phone"
                        ></el-option>
                        <el-option
                          label="Email address"
                          value="email"
                        ></el-option>
                        <el-option
                          label="Account number, Iban"
                          value="account"
                        ></el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item
                      :label="paymentType[form.payByType]"
                      prop="payeeInfo"
                    >
                      <el-input
                        v-model="form.payeeInfo"
                        class="form-control-alternative"
                      ></el-input>
                    </el-form-item> -->

                      <el-form-item label="Amount" prop="amount">
                        <el-input
                          v-model="form.amount"
                          placeholder="Amount"
                          class="form-control-alternative"
                        ></el-input>
                      </el-form-item>

                      <el-form-item label="Reference" prop="reference">
                        <el-input
                          v-model="form.reference"
                          placeholder="Reference"
                          v-input-filter
                          class="form-control-alternative"
                        ></el-input>
                      </el-form-item>
                    </el-form>
                  </el-card>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row justify-content-xl-center">
                <div class="col-xl-12 text-center">
                  <el-button @click="resetForm()">Cancel</el-button>

                  <el-button
                    type="primary"
                    @click="onSubmit()"
                    v-loading="submiting"
                    :disabled="submiting"
                    >Confirm</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <Shortcut />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FxApi from "@/api/fx";
import Utils from "@/utils";
import BankApi from "@/api/bankaccount";
import { isMobile } from "@/utils/helpers";
import Shortcut from "@/components/MenuShortcut.vue";
import Payee from "@/api/payee";
export default {
  components: {
    Shortcut,
  },
  computed: {
    isBusiness() {
      return this.$store.getters.business;
    },
    labelPosition() {
      return isMobile() ? "top" : "left";
    },
    getPayeeInfoLabel() {
      return `${this.paymentType[this.form.payByType]}`;
    },
  },
  data() {
    return {
      submiting: false,

      loading: false,

      bankAccount: [],
      loadingBalance: false,
      balance: "",
      form: {
        bankholderid: "",
        payByType: "phone", //values : account, phone, email
        payeeInfo: "",
        currencyCode: "",
        amount: "",
        reference: "",
      },
      formRule: {
        bankholderid: [
          {
            required: true,
            message: "Please select BankholderID",
            trigger: "blur",
          },
        ],
        payByType: [
          {
            required: true,
            message: "Please select type of payment",
            trigger: "blur",
          },
        ],
        payeeInfo: [
          {
            required: true,
            message: `Please enter value `,
            trigger: "blur",
          },
        ],
        account: [
          {
            required: true,
            message: "Please enter Account No./IBAN",
            trigger: "blur",
          },
        ],
        amount: [
          { required: true, message: "Please enter amount", trigger: "blur" },
        ],
        reference: [
          {
            required: true,
            message: "Please enter reference",
            trigger: "blur",
          },
        ],
      },
      model: {
        payByAccount: "",
        currencyCode: "",
        payByType: "",
        payeeInfo: "",
        amount: 0,
        reference: "",
      },
      payeeInfo: {
        name: "",
        email: "",
        payByType: "",
        payeeInfo: "",
        phonenumber: "",
        accountnumber: "",
        currencyCode: "",
      },
      paymentType: {
        phone: "Pay by Phone Number",
        email: "Pay by Email address",
        account: "Pay by Account",
      },
    };
  },
  methods: {
    getPayeePayment() {
      this.loading = true;
      Payee.getPayeeInstant(this.$route.params.uid).then(
        async ({ result, data }) => {
          if (result) {
            //console.log(data);
            this.payeeInfo = data;

            this.model.currencyCode = data.currencyCode;

            if (data.phonenumber) {
              this.model.payByType = "phone";
              this.model.payeeInfo = data.phonenumber;
            }
            if (data.email) {
              this.model.payByType = "email";
              this.model.payeeInfo = data.email;
            }
            if (data.accountnumber) {
              this.model.payByType = "account";
              this.model.payeeInfo = data.accountnumber;
            }

            await this.getBankAccount(data.currencyCode);
          } else {
            this.loading = false;
            this.$swal("Message", message, "error");
          }
        }
      );
    },
    async getBankAccount(currency) {
      this.loading = true;
      let _bankAccount = await Utils.getBankAccount();
      this.bankAccount = Utils.findObjectArray(
        _bankAccount,
        currency,
        "currency"
      );
      this.loading = false;
    },
    getBalance(bankholderid) {
      this.balance = "";
      this.loadingBalance = true;
      BankApi.getAccountBalance(bankholderid).then(({ result, data }) => {
        this.loadingBalance = false;
        if (result) {
          this.balance = `Balance : ${data.balance}`;
        }
      });
    },
    resetForm() {
      this.$router.push("/instant-bank-payment");
    },

    onSubmit() {
      this.$refs.formInstant.validate((valid) => {
        if (valid) {
          this.model.amount = parseFloat(this.form.amount);
          this.model.payByAccount = this.form.bankholderid;
          this.model.reference = this.form.reference;

          this.submiting = true;
          FxApi.instantV2(this.model).then(({ result, message }) => {
            this.submiting = false;
            if (result) {
              this.$swal
                .fire({
                  title: "Payment success",
                  text: "Do you want to make another instant payment?",
                  icon: "success",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes",
                  cancelButtonText: "No, Go to statement",
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    this.$router.push("/instant-bank-payment");
                  } else {
                    this.$router.push(
                      "/statement?bankholderid=" + this.model.payByAccount
                    );
                  }
                });
            } else {
              this.$swal("Message", message, "error");
            }
          });
        }
      });
    },
  },
  async created() {
    //await this.getBankAccount();
    this.getPayeePayment();
  },
};
</script>
<style></style>
